import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'react-bootstrap';

import { ReactComponent as Close } from './images/close.svg';
import { ReactComponent as Dot } from './images/dot.svg';
import { ReactComponent as Wallet } from './images/wallet.svg';

function Popup({
  state: { account, selectedParticipant },
  onClaim,
  onConnect,
  onClose,
  isMetamaskAvailable,
}) {
  const ref = useRef();

  useOnClickOutside(ref, onClose);
  useHideScrollbar(selectedParticipant);

  if (!selectedParticipant) return null;

  return ReactDOM.createPortal(
    <div className="Popup">
      <div className="content" ref={ref}>
        <Close className="close" onClick={onClose} />
        <Wallet />
        <span className="address">{selectedParticipant.participant}</span>
        <div className="summary">
          <span className="label">Current liquidity provided</span>
          <span className="liquidity">
            <span className="value">
              <span>{selectedParticipant.liquidity0}</span>
              &nbsp;
              <span className="currency">MYST</span>
            </span>
            &nbsp;/&nbsp;
            <span className="value">
              <span>{selectedParticipant.liquidity1}</span>
              &nbsp;
              <span className="currency">ETH</span>
            </span>
          </span>
          <div className="divider" />
          <span className="label">Total earnings</span>
          <span className="value">
            <span>{selectedParticipant.totalEarned}</span>
            &nbsp;
            <span className="currency">MYST</span>
          </span>
          <div className="divider" />
          <span className="label">Already claimed</span>
          <span className="value">
            <span>{selectedParticipant.paid}</span>
            &nbsp;
            <span className="currency">MYST</span>
          </span>
          <div className="divider" />
          <span className="label">Available to claim</span>
          <span className="value">
            <span>{selectedParticipant.claimable}</span>
            &nbsp;
            <span className="currency">MYST</span>
          </span>
        </div>
        {isMetamaskAvailable && !account && (
          <Button
            className="connect"
            variant="outline-light"
            onClick={onConnect}
          >
            <Dot className="dot" />
            Connect to Metamask
          </Button>
        )}
        {isMetamaskAvailable && account === selectedParticipant.participant && (
          <Button className="claim" variant="outline-light" onClick={onClaim}>
            Claim Now
          </Button>
        )}
      </div>
    </div>,
    document.body
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

function useHideScrollbar(condition) {
  useEffect(() => {
    const { documentElement } = window.document;
    if (condition) {
      const { scrollWidth: a } = documentElement;
      documentElement.style.overflow = 'hidden';
      const { scrollWidth: b } = documentElement;
      documentElement.style.paddingRight = `${b - a}px`;
    } else {
      documentElement.style.overflow = 'initial';
      documentElement.style.paddingRight = 0;
    }
  }, [condition]);
}

export default Popup;
