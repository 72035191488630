import { Container, Row } from 'react-bootstrap';

function Header() {
  return (
    <div className="Header">
      <Container>
        <Row>
          <a
            className="logo"
            href="https://mysterium.network/"
            target="_blank"
            rel="noreferrer"
          >
            Mysterium Network
          </a>
        </Row>
        <Row>
          <h2>Provide MYST / ETH uniswap rewards dashboard</h2>
        </Row>
        <Row>
          <h3>
            Mysterium Network team was running <a href="https://mysterium.network/blog/myst-liquidity-on-uniswap/">
              uniswap liquidity engagement campaign</a> till March 1st 2021. Follow us on <a href="https://twitter.com/MysteriumNet" target="_blank">Twitter</a> for future announcements.
          </h3>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
