import { useEffect, useReducer } from 'react';
import { differenceInDays } from 'date-fns';
import Web3 from 'web3';

import Claims from './Claims';
import Dashboard from './Dashboard';
import Footer from './Footer';
import Header from './Header';
import Popup from './Popup';

import Metamask from './metamask';
import Rewarder from './rewarder';

import { readJSON, sleep, debounce } from './utils';

const metamask = new Metamask();

const initialState = {
  account: null,
  rewarder: null,
  dayOfPeriod: 0,
  participants: [],
  sortField: 'totalEarned',
  sortOrder: 'desc',
  preparedParticipants: [],
  selectedParticipant: null,
};

const ACTIONS = {
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_REWARDER: 'SET_REWARDER',
  UPDATE_PARTICIPANTS: 'UPDATE_PARTICIPANTS',
  SORT_FIELD: 'SORT_FIELD',
  CHANGE_PARTICIPANT: 'CHANGE_PARTICIPANT',
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SET_ACCOUNT:
      return { ...state, account: payload };
    case ACTIONS.SET_REWARDER:
      return { ...state, rewarder: payload };
    case ACTIONS.UPDATE_PARTICIPANTS:
      const { rewarder } = state;
      const participants = rewarder
        .getParticipants()
        .sort(getComparator({ sortField: 'totalEarned', sortOrder: 'desc' }))
        .map((participant, idx) => ({
          ...participant,
          rank: idx + 1,
          liquidity0: rewarder.toTokens(participant.liquidity0).toFixed(2),
          liquidity1: rewarder.toTokens(participant.liquidity1).toFixed(2),
          totalEarned: rewarder.toTokens(participant.totalEarned).toFixed(2),
          paid: rewarder.toTokens(participant.paid).toFixed(2),
          claimable: ((value) => (value > 0 ? value.toFixed(2) : 0))(
            rewarder.toTokens(participant.claimable)
          ),
        }));
      return {
        ...state,
        dayOfPeriod: differenceInDays(Date.now(), payload) + 1,
        participants,
        preparedParticipants: participants.sort(getComparator(state)),
      };
    case ACTIONS.SORT_FIELD:
      const sortField = payload;
      const sortOrder =
        state.sortField === payload
          ? state.sortOrder === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc';
      return {
        ...state,
        sortField,
        sortOrder,
        preparedParticipants: state.participants.sort(
          getComparator({ sortField, sortOrder })
        ),
      };
    case ACTIONS.CHANGE_PARTICIPANT:
      return {
        ...state,
        selectedParticipant: payload,
      };
    default:
      throw new Error('Wrong action');
  }
}

function getComparator({ sortField, sortOrder }) {
  return (a, b) => {
    const x = a[sortField];
    const y = b[sortField];
    return Number(sortOrder === 'asc' ? x - y : y - x);
  };
}

function useInitialize(state, dispatch) {
  useEffect(() => {
    const accountChanged = (account) =>
      dispatch({
        type: ACTIONS.SET_ACCOUNT,
        payload: Web3.utils.toChecksumAddress(account),
      });
    const disconnected = () =>
      dispatch({ type: ACTIONS.SET_ACCOUNT, payload: null });

    (async () => {
      if (state.account) return;

      metamask.on('accountChanged', accountChanged);
      metamask.on('disconnect', disconnected);

      await metamask.initialize();
      const response = await fetch(process.env.PUBLIC_URL + '/snapshot.json');
      const json = await response.text();
      const snapshot = readJSON(json);
      const rewarder = new Rewarder(
        snapshot,
        async (file) => {
          // ensure file is fetched
          for (let i = 0; i < 5; i++) {
            try {
              const response = await fetch(
                process.env.PUBLIC_URL + '/roots/' + file
              );
              return response.json();
            } catch {
              await sleep(10000 + i * 1000);
            }
          }
          throw new Error(`Unable to fetch: ${file}`);
        },
        metamask.web3
      );
      await rewarder.initialize();
      dispatch({ type: ACTIONS.SET_REWARDER, payload: rewarder });
    })();

    return () => {
      metamask.off('accountChanged', accountChanged);
      metamask.off('disconnect', disconnected);
    };
  }, [state.account, dispatch]);
}

function useUpdateParticipants(state, dispatch) {
  useEffect(() => {
    if (!state.rewarder) return;

    const listener = debounce(async () => {
      dispatch({
        type: ACTIONS.UPDATE_PARTICIPANTS,
        payload: await state.rewarder.getPeriodStartTime(),
      });
    }, 2000);

    state.rewarder.on('update', listener);

    return () => {
      state.rewarder.off('update', listener);
    };
  }, [state.rewarder, dispatch]);
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  useInitialize(state, dispatch);
  useUpdateParticipants(state, dispatch);

  const claimReward = async () => {
    await state.rewarder.claim(state.account, state.account);
  };

  const connectMetamask = async () => {
    await metamask.connect();
  };

  const changeSortOrder = (key) => {
    dispatch({ type: ACTIONS.SORT_FIELD, payload: key });
  };

  const openPopup = (participant) => {
    dispatch({ type: ACTIONS.CHANGE_PARTICIPANT, payload: participant });
  };

  const closePopup = () => {
    dispatch({ type: ACTIONS.CHANGE_PARTICIPANT, payload: null });
  };

  return (
    <div className="App">
      <Header />
      {/* <Dashboard state={state} /> */}
      <Claims
        state={state}
        onHeadClick={changeSortOrder}
        onRowClick={openPopup}
      />
      <Footer />
      <Popup
        state={state}
        isMetamaskAvailable={metamask.available}
        onClaim={claimReward}
        onConnect={connectMetamask}
        onClose={closePopup}
      />
    </div>
  );
}

export default App;
