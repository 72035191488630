import { MAX_REORG_DEPTH, sleep } from './utils';

export default class BlockStreamer {
  constructor(web3) {
    this.web3 = web3;
    this.stopped = false;
  }

  async *blocks(fromBlock, pollInterval = 60000) {
    let blockNumber = null;
    while (!this.stopped) {
      blockNumber = await this.web3.eth.getBlockNumber();
      if (fromBlock <= blockNumber) break;
      await sleep(pollInterval);
    }
    let lastBlock = Number(fromBlock);

    while (true) {
      blockNumber = await this.web3.eth.getBlockNumber();
      while (lastBlock <= blockNumber) {
        yield await this.web3.eth.getBlock(lastBlock);
        lastBlock++;
      }
      await sleep(pollInterval);
    }
  }

  async rewind(hash, known) {
    for (let i = 0; i < MAX_REORG_DEPTH; i++) {
      if (known[hash]) return known[hash];
      const block = await this.web3.eth.getBlock(hash);
      hash = block.parentHash;
    }
    throw new Error('Common block not found');
  }

  stop() {
    this.stopped = true;
  }
}
