import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col className="description">
            <p>
              BlockDev AG, Seestrasse 5 CH-6300 Zug, Switzerland. Read our
              Website Privacy Policy and{' '}
              <a
                href="https://mysterium.network/terms-conditions/"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
              .
            </p>
            <p>
              Mysterium Network is developed by Blockdev AG.Discovery services
              within Mysterium Network are run by NetSys Inc.
            </p>
            <p>© 2020 Mysterium Network.</p>
          </Col>
          <Col className="links">
            <a
              className="medium"
              href="https://medium.com/mysterium-network"
              target="_blank"
              rel="noreferrer"
            >
              Medium
            </a>
            <a
              className="newsletter"
              href="https://mailchi.mp/mysterium.network/newsletter"
              target="_blank"
              rel="noreferrer"
            >
              Newsletter
            </a>
            <a
              className="youtube"
              href="https://www.youtube.com/channel/UCBxzWnZEHvuj-nfP00YImHQ"
              target="_blank"
              rel="noreferrer"
            >
              Youtube
            </a>
            <a
              className="twitter"
              href="https://twitter.com/MysteriumNet"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="telegram"
              href="https://t.me/mysterium_network"
              target="_blank"
              rel="noreferrer"
            >
              Telegram
            </a>
            <a
              className="reddit"
              href="https://www.reddit.com/domain/mysterium.network/"
              target="_blank"
              rel="noreferrer"
            >
              Reddit
            </a>
            <a
              className="blog"
              href="https://mysterium.network/blog/"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
            <a
              className="discord"
              href="https://discordapp.com/invite/n3vtSwc"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
