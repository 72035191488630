import detectEthereumProvider from '@metamask/detect-provider';
import EventEmitter from 'eventemitter3';
import Web3 from 'web3';

const ALTERNATIVE_PROVIDER =
  'https://mainnet.infura.io/v3/b6dffd0773fd48e28b11595b0a504f50';

/**
 * Class to work with Metamask extension.
 *
 * "initialize()" must be called before working with this class
 *
 * Events:
 * - `accountsChanged` - user changed account in Metamask
 * - `connect` - user connected Metamask
 * - `disconnect` - user disconnected Metamask
 * - `error` - some error occured
 */
export default class Metamask extends EventEmitter {
  constructor() {
    super();
    this.available = false;
    this.account = null;
    this.web3 = null;
    this.connected = false;
  }

  async initialize() {
    try {
      if (this.web3) return;

      const provider = await detectEthereumProvider({ timeout: 1000 });
      if (provider) {
        this.available = true; // if there is provider detected, most probably it is Metamask

        if (provider !== window.ethereum) {
          console.error('Do you have multiple wallets installed?');
        }
        window.ethereum.autoRefreshOnNetworkChange = false; // silent the warning
        this.web3 = new Web3(provider);
        sessionStorage.setItem('chainId', null);
        window.ethereum.on('chainChanged', (chainId) => {
          if (sessionStorage.getItem('chainId') !== chainId) {
            sessionStorage.setItem('chainId', chainId);
            window.location.reload();
          }
        });
        window.ethereum.on('accountsChanged', (accounts) =>
          this.handleAccountsChanged(accounts)
        );
        window.ethereum.on('disconnect', () => this.emit('disconnect'));
      } else if (window.web3) {
        console.log('Current provider', window.web3.currentProvider)
        this.web3 = new Web3(window.web3.currentProvider)

        console.warn('Using injected web3, not a modern MetaMask');
      } else {
        this.web3 = new Web3(ALTERNATIVE_PROVIDER);
        console.warn('MetaMask is not installed, using alternative provider');
      }
    } catch (err) {
      this.connected = false;
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log('Please connect to MetaMask.');
        this.emit('disconnect');
      } else {
        this.emit('error', err);
        console.error(err);
      }
      throw err;
    }
  }

  /**
   * This should be called only on user request
   */
  async connect() {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    this.handleAccountsChanged(accounts);
  }

  handleAccountsChanged(accounts) {
    this.connected = accounts.length > 0;
    if (accounts.length === 0) {
      this.connected = false;
      this.emit('disconnect');
      // MetaMask is locked or the user has not connected any accounts
      console.log('Please connect to MetaMask.');
    } else if (accounts[0] !== this.account) {
      this.account = accounts[0];
      this.connected = true;
      this.emit('connect');
      this.emit('accountChanged', this.account);
    }
  }
}
