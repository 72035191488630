/* global BigInt */

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const BANNED = 'BANNED'
export const LIQUIDITY = 'LIQUIDITY'
export const FIRST_SEEN = 'FIRST_SEEN'
export const TRANSFER_TOPIC = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
export const SYNC_TOPIC = '0x1c411e9a96e071241c2f21f7726b17ae89e3cab4c78be50e062b03a9fffbbad1'
export const MAX_REORG_DEPTH = 20
export const INFO = 'INFO'
export const CLAIMABLE = 'CLAIMABLE'
export const MERKLE_TREE = 'MERKLE_TREE'

export async function* streamBlocks(web3) {
  let resolve = null
  let reject = null
  let promise = new Promise((r, e) => {
    resolve = r
    reject = e
  })
  let done = false

  web3.eth
    .subscribe('newBlockHeaders', (error) => {
      if (error) {
        reject(error)
      }
    })
    .on('data', (blockHeader) => {
      resolve(blockHeader)
      promise = new Promise((r, e) => {
        resolve = r
        reject = e
      })
    })
    .on('error', (error) => {
      done = true
      reject(error)
    })

  while (!done) {
    yield await promise
  }
}

export function add(cache, participant, amount) {
  if (!cache[participant]) {
    cache[participant] = 0n
  }
  cache[participant] += amount
}

export async function sleep(timeout = 500) {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

export function readJSON(json) {
  return JSON.parse(json, (_, value) => {
    if (typeof value === 'string') {
      const m = value.match(/(-?\d+)n/)
      if (m && m[0] === value) {
        return BigInt(m[1])
      }
    }
    return value
  })
}

export function writeJSON(data) {
  return JSON.stringify(
    data,
    (key, value) => (typeof value === 'bigint' ? `${value.toString()}n` : value), // return everything else unchanged
    '  '
  )
}

/**
 * @template T
 * @param {AsyncIterator<T>} stream
 * @returns {Promise<{stream: AsyncIterator<T>} & IteratorResult<T>>}
 */
async function advance(stream) {
  return {
    stream,
    ...(await stream.next()),
  }
}

/**
 * Merges multiple increasing streams into single one using given comparator
 * @template T
 * @param {Array<AsyncIterator<T>|Iterator<T>>} streams multiple increasing streams
 * @param {function(a: T, b: T): number} compare how to compare elements of the stream
 * @returns {AsyncIterator<T>} single increasing stream
 */
export async function* mergeIncreasingStreams(streams, compare) {
  let list = await Promise.all(streams.map(advance))
  // inverse sort, so smalest is at the and
  const cmp = (a, b) => compare(b.value, a.value)
  for (; ;) {
    list = list.filter((item) => !item.done)
    if (list.length === 0) break
    list.sort(cmp)
    const { value, stream } = list.pop() // smallest
    yield value
    list.push(await advance(stream))
  }
}

export function conversion(precision = 8, tokenDecimals = 18) {
  return {
    toTokens(amount) {
      return Number((amount * 10n ** BigInt(precision)) / 10n ** BigInt(tokenDecimals)) / 10 ** precision
    },
    fromTokens(amount) {
      return (BigInt(amount * 10 ** precision) * 10n ** BigInt(tokenDecimals)) / 10n ** BigInt(precision)
    },
  }
}

export async function* streamArray(arr) {
  for (const item of arr) {
    yield item
  }
}

export function debounce(cb, ms) {
  let cooldown = false;

  return function () {
    if (cooldown) return;

    cb.apply(this, arguments);

    cooldown = true;

    setTimeout(() => (cooldown = false), ms);
  };
}
