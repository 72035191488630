import { useMemo } from 'react';
import { Button, Container, Table, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import clsx from 'clsx';

import { ReactComponent as Chevron } from './images/chevron.svg';

function Claims({
  state: { account, preparedParticipants, sortField, sortOrder },
  onHeadClick,
  onRowClick,
}) {
  const clickHead = (key) => () => onHeadClick(key);

  const rows = useMemo(
    () =>
      (preparedParticipants || []).map((participant) => (
        <tr
          className={clsx('participant', {
            current: participant.participant === account,
          })}
          onClick={() => onRowClick(participant)}
          key={participant.participant}
        >
          <td>{participant.rank}</td>
          <td>{participant.participant}</td>
          <td>{format(participant.firstSeenTime, 'dd/MM/yyyy')}</td>
          <td>{participant.liquidity0}</td>
          <td>{participant.liquidity1}</td>
          <td>{participant.totalEarned}</td>
          {/* <td>
            {participant.claimable}
            <Button className="claim">Claim</Button>
          </td> */}
        </tr>
      )),
    [account, preparedParticipants, onRowClick]
  );

  return (
    <div className="Claims">
      <h2>Historical leaderboard</h2>
      <Container>
        {preparedParticipants.length > 0 ? (
          <div className="table-wrapper">
            <Table>
              <thead>
                <tr>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'rank',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('rank')}
                  >
                    <p>Rank</p>
                    <Chevron className="chevron" />
                  </th>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'participant',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('participant')}
                  >
                    <p>Address</p>
                    <Chevron className="chevron" />
                  </th>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'firstSeenTime',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('firstSeenTime')}
                  >
                    <p>Entrance to</p>
                    <p>the program</p>
                    <Chevron className="chevron" />
                  </th>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'liquidity0',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('liquidity0')}
                  >
                    <p>Provided liquidity,</p>
                    <p className="currency">MYST</p>
                    <Chevron className="chevron" />
                  </th>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'liquidity1',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('liquidity1')}
                  >
                    <p>Provided liquidity,</p>
                    <p className="currency">ETH</p>
                    <Chevron className="chevron" />
                  </th>
                  <th
                    className={clsx(
                      {
                        active: sortField === 'totalEarned',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('totalEarned')}
                  >
                    <p>Total earnings,</p>
                    <p className="currency">MYST</p>
                    <Chevron className="chevron" />
                  </th>
                  {/* <th
                    className={clsx(
                      {
                        active: sortField === 'claimable',
                      },
                      sortOrder
                    )}
                    onClick={clickHead('claimable')}
                  >
                    <p>Available to claim,</p>
                    <p className="currency">MYST</p>
                    <Chevron className="chevron" />
                  </th> */}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        ) : (
            <Spinner animation="border" />
          )}
      </Container>
    </div>
  );
}

export default Claims;
